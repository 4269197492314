<template>
  <section id="card-style-variation">

    <!-- outline color -->

    <b-row>
      <b-col md="6"> 
        <router-link to="/rekap_presensi">
          <statistic-card-vertical icon="UserCheckIcon" :statistic="dataStatstik.aktif_hari_ini" statistic-title="Presensi Hari Ini" color="primary" /> 
        </router-link>
      </b-col>
      <b-col md="6"> 
        <router-link to="/rekap_presensi">
          <statistic-card-vertical icon="UsersIcon" :statistic="dataStatstik.user_aktif" statistic-title="Total User Aktif" color="info" /> 
        </router-link>
      </b-col>
      <b-col md="6"> 
          <statistic-card-vertical icon="GridIcon" :statistic="dataStatstik.kelompok_aktif" statistic-title="Kelompok Aktif" color="danger" /> 
      </b-col>
      <b-col md="6"> 
          <statistic-card-vertical icon="MapPinIcon" :statistic="dataStatstik.kordinaat_aktif" statistic-title="Koordinat " color="warning" /> 
      </b-col>
      
    </b-row>
   
    <!-- <b-row>
      <b-col md="12" xl="12">
        <b-card border-variant="primary" title="Presensi Hari ini" bg-variant="" class="shadow-none">
          <b-card-text>
            menginfokan jumlah user yang melakian presensi hari ini sesuai dengan titik kordinat
          </b-card-text>
        </b-card>
      </b-col>
 

    </b-row> -->
  </section>
</template>

<script>
  import {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
  } from 'bootstrap-vue'
  import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardText,
      BCardTitle,
      StatisticCardVertical
    },
    props:{
      dataStatstik : {}
    },
    data() {
      return {
        solidColor: [{
            bg: 'primary',
            title: 'Primary card title'
          },
          {
            bg: 'secondary',
            title: 'Secondary card title'
          },
          {
            bg: 'success',
            title: 'Success card title'
          },
          {
            bg: 'danger',
            title: 'Danger card title'
          },
          {
            bg: 'warning',
            title: 'Warning card title'
          },
          {
            bg: 'info',
            title: 'Info card title'
          },
        ],
      }
    },
  }
</script>
