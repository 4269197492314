<template>
  <div>
     <admin></admin>
  </div>
</template>

<script> 
import admin from './admin/index.vue';
import Base from '@/config/Mixins_base'
import Add from '../kelompok/list/add.vue';
export default {
  mixins :[Base],
  data(){
    return{

    }
  },
  components:{ 
    admin,
    Add
  },
  mounted(){ 
    this.cek_token();
  },
  methods:{

  }

}
</script>

<style>

</style>