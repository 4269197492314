<template>
  <div>
    <b-row>
      <b-col md="4" >  
        <img src='@/assets/images/logo/cinta_lapaoangan_dashboard.png' width="100%" class="img-responsive img-rounded mb-1"/>  
        <presensi  :dataStatstik = dataStatstik></presensi>
        <!-- <kelompok></kelompok>  -->

      </b-col>
      <b-col md="8">
      
        <peta :mapsData =mapsData></peta>
        <!-- <b-row>
          <b-col md="6">
            <kelompokTerbaik></kelompokTerbaik>
          </b-col>
          <b-col md="6">
            <kelompokKurangbaik></kelompokKurangbaik>
          </b-col> 
        </b-row> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from '@/config/Axios';
import Base from '@/config/Mixins_base';
  import kelompok from './kelompok.vue';
  import peta from './peta.vue';
  import presensi from './Presensi.vue';
  import kelompokKurangbaik from './kelompokKurangbaik.vue';
  import kelompokTerbaik from './kelompokTerbaik.vue';
  import rekapPresensi from './rekapPresensi.vue';
  import {
    BRow,
    BCol,
    BButton, BCard
  } from 'bootstrap-vue'

  export default {
    components: {
      BRow,
      BCol,
      BButton,BCard,
      peta,
      presensi,
      kelompok,kelompokKurangbaik, kelompokTerbaik, rekapPresensi
    },
    mixins:[Base],
    data(){
      return {
        dataStatstik : {},
        mapsData:[]
      }
    }, mounted(){
      this.load_statistik();
      this.maps()

    }, methods:{
      async load_statistik() {
          const self = this;
          await axios({
            method: 'PUT',
            url: '/api/dashboard/statistik/load_data',
            data: {
              
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
            })
            .then(function (response) {
            self.dataStatstik = response.data.result; 
      
            }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
            });
          },
          async maps() {
              const self = this;
              await axios({
                method: 'PUT',
                url: '/api/dashboard/statistik/maps',
                data: {
                  
                },
                headers: {
                  'Authorization': self.isAuthenticated
                }
                })
                .then(function (response) {
                self.mapsData = response.data.result; 
          
                }).catch(err => {
                self.pesan = err.message;
                self.notification('warning', "Error", err.message);
                });
              },
    }

  }
</script>

<style>

</style>